import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { initRaygun, initHotjar } from '@livingsecurity/cyberblocks';

import Home from './views/Home';
import '@livingsecurity/puzzles/dist/styles/index.css';

import 'core-js';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

const Hotspot = lazy(() => import('./views/Hotspot'));
const Classify = lazy(() => import('./views/Classify'));
const Unscramble = lazy(() => import('./views/Unscramble'));
const Vishing = lazy(() => import('./views/Vishing'));
const Phishing = lazy(() => import('./views/Phishing'));
const CameraFeed = lazy(() => import('./views/CameraFeed'));
const ImageComplete = lazy(() => import('./views/ImageComplete'));
const Flags = lazy(() => import('./views/Flags'));
const Text = lazy(() => import('./views/Text'));
const DatingGame = lazy(() => import('./views/DatingGame'));
const Corkboard = lazy(() => import('./views/Corkboard'));
const EmailBuilder = lazy(() => import('./views/EmailBuilder'));
const ReOrder = lazy(() => import('./views/ReOrder'));
const Respond = lazy(() => import('./views/Respond'));

const cyberEscapeOnline = '.cyberescapeonline.com';
const livingSecurity = '.livingsecurity.com';

// Main puzzle dashboard
class PuzzleDashboard extends React.Component {
  constructor(props) {
    super(props);

    initRaygun(window.location.hostname);
    initHotjar(window.location.hostname);
  }

  renderPuzzle = () => {
    if (
      window.location.href.indexOf(`hotspot${cyberEscapeOnline}`) > -1 ||
      window.location.href.indexOf(`cmhotspot${livingSecurity}`) > -1
    ) {
      return <Hotspot puzzle='criticalMass' />;
    } else if (
      window.location.href.indexOf(`hotspot${livingSecurity}`) > -1 ||
      window.location.href.indexOf(`zombieroom${livingSecurity}`) > -1 ||
      window.location.href.indexOf(`guardians${livingSecurity}`) > -1 ||
      window.location.href.indexOf(`parkhack${livingSecurity}`) > -1 ||
      window.location.href.indexOf(`spotpatrol${livingSecurity}`) > -1 ||
      window.location.href.indexOf(`storespot${livingSecurity}`) > -1
    ) {
      return <Hotspot />;
    } else if (
      window.location.href.indexOf(`classify${cyberEscapeOnline}`) > -1 ||
      window.location.href.indexOf(`cmclassify${livingSecurity}`) > -1
    ) {
      return <Classify puzzle='criticalMass' />;
    } else if (
      window.location.href.indexOf(`philips-classify${livingSecurity}`) > -1
    ) {
      return <Classify puzzle='philipsClassify' />;
    } else if (
      window.location.href.indexOf(`unscramble${cyberEscapeOnline}`) > -1 ||
      window.location.href.indexOf(`cmunscramble${livingSecurity}`) > -1
    ) {
      return <Unscramble puzzle='criticalMass' />;
    } else if (
      window.location.href.indexOf(`unscramble${livingSecurity}`) > -1 ||
      window.location.href.indexOf(`unscrambler${livingSecurity}`) > -1 ||
      window.location.href.indexOf(`emojipass${livingSecurity}`) > -1
    ) {
      return <Unscramble />;
    } else if (
      window.location.href.indexOf(`vishing${cyberEscapeOnline}`) > -1 ||
      window.location.href.indexOf(`cmvishing${livingSecurity}`) > -1
    ) {
      return <Vishing puzzle='criticalMass' />;
    } else if (
      window.location.href.indexOf(`complete${cyberEscapeOnline}`) > -1 ||
      window.location.href.indexOf(`cmcomplete${livingSecurity}`) > -1 ||
      window.location.href.indexOf(`philips-complete${livingSecurity}`) > -1
    ) {
      return <ImageComplete />;
    } else if (
      window.location.href.indexOf(`feed${cyberEscapeOnline}`) > -1 ||
      window.location.href.indexOf(`cmfeed${livingSecurity}`) > -1
    ) {
      return <CameraFeed puzzle='criticalMass' />;
    } else if (window.location.href.indexOf('cmtext') > -1) {
      return <Text puzzle='criticalMass' />;
    } else if (
      window.location.href.indexOf(`flags${cyberEscapeOnline}`) > -1 ||
      window.location.href.indexOf(`cmflags${livingSecurity}`) > -1
    ) {
      return <Flags puzzle='criticalMass' />;
    } else if (window.location.href.indexOf(`flags${livingSecurity}`) > -1) {
      return <Flags puzzle='phishingIrl' />;
    } else if (window.location.href.indexOf(`spoilthevish${livingSecurity}`) > -1) {
      return <Vishing puzzle='phishingIrl' />;
    } else if (window.location.href.indexOf(`highvalue${livingSecurity}`) > -1) {
      return <DatingGame puzzleVariation='trainingGrounds' title='High Value' />;
    } else if (window.location.href.indexOf(`hygiene${livingSecurity}`) > -1) {
      return <DatingGame puzzleVariation='entranceExam' title='Hygiene' />;
    } else if (window.location.href.indexOf(`storeinsider${livingSecurity}`) > -1) {
      return <DatingGame title='Store Insider' />;
    } else if (window.location.href.indexOf(`criticalmap${livingSecurity}`) > -1) {
      return <Corkboard puzzleVariation='trainingGrounds' title='Critical Map' />;
    } else if (window.location.href.indexOf(`highlevel${livingSecurity}`) > -1) {
      return <Corkboard puzzleVariation='entranceExam' title='High Level' />;
    } else if (window.location.href.indexOf(`livebec${livingSecurity}`) > -1) {
      return <EmailBuilder puzzle='trainingGrounds' />;
    } else if (window.location.href.indexOf(`generalbec${livingSecurity}`) > -1) {
      return <EmailBuilder puzzle='entranceExam' />;
    } else if (window.location.href.indexOf(`wall-e-worldphish${livingSecurity}`) > -1) {
      return <EmailBuilder puzzle='wallEWorldPhish' />;
    } else if (window.location.href.indexOf(`reorder${livingSecurity}`) > -1) {
      return <ReOrder puzzleVariation='entranceExam' />;
    } else if (window.location.href.indexOf(`storereact${livingSecurity}`) > -1) {
      return <Respond title='Store React' />;
    } else if (window.location.href.indexOf(`react${livingSecurity}`) > -1) {
      return <Respond puzzleVariation='entranceExam' />;
    } else if (window.location.href.indexOf(`bethevish${livingSecurity}`) > -1) {
      return <Vishing title='Be the Vish' puzzleVariation='entranceExam' />;
    } else if (window.location.href.indexOf(`vishafridge${livingSecurity}`) > -1) {
      return <Vishing title='Be the Vish' puzzleVariation='entranceExam' />;
    } else {
      return this.renderRoute();
    }
  };

  // These are here for local testing. It allows us to navigate to http://localhost:3000/hotspot and see that puzzle.
  renderRoute = () => {
    return (
      <Switch>
        <Route path='/feed' component={CameraFeed} />
        <Route path={['/vishing', '/vish2', '/vish4', '/vish6', '/spoilthevish', '/bethevish', '/vishafridge']}>
          <Vishing title='Be the Vish' />
        </Route>
        <Route
          path={['/phish1', '/phish3', '/phish5', '/phishery', '/phishing', '/rawphish', '/sushi']}
          component={Phishing}
        />
        <Route path={['/classify', '/philips-classify']}>
          <Classify />
        </Route>
        <Route path={['/unscramble', '/unscrambler', '/emojipass', '/critical']} component={Unscramble} />
        <Route
          path={['/hotspot', '/zombieroom', '/guardians', '/parkhack', '/criticalMass', '/spotpatrol', '/storespot']}
        >
          <Hotspot />
        </Route>
        <Route path={['/complete', '/philips-complete']}>
          <ImageComplete />
        </Route>
        <Route path='/flags'>
          <Flags puzzle='phishingIrl' />
        </Route>
        <Route path={['/dating', '/highvalue', '/hygiene', '/storeinsider']}>
          <DatingGame title='Hygiene' />
        </Route>
        <Route path={['/corkboard', '/criticalmap', '/highlevel']}>
          <Corkboard title='High Level' />
        </Route>
        <Route path={['/emailbuilder', '/livebec', '/generalbec', '/wall-e-worldphish']}>
          <EmailBuilder />
        </Route>
        <Route path={['/reorder']}>
          <ReOrder puzzleVariation='entranceExam' />
        </Route>
        <Route path={['/react', '/storereact']}>
          <Respond title='React' />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    );
  };

  render() {
    return (
      <div>
        <Router>
          <Suspense fallback={<div style={{ background: 'black' }} />}>{this.renderPuzzle()}</Suspense>
        </Router>
      </div>
    );
  }
}

ReactDOM.render(<PuzzleDashboard />, document.getElementById('root'));
