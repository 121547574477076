import React from 'react';
import { Logo } from '@livingsecurity/cyberblocks';

import '../styles/Home.scss';

function Home() {
  return (
    <div className='puzzle-dashboard'>
      <Logo width={500} />
    </div>
  );
}

export default Home;
